<template>
  <div class="dashboard__main__content__container">
    <title-bar title="Fund Wallet">
      <records-filter
        v-click-outside="closeDropdown"
        data="fund-wallet"
        :selected="['date']"
        :show-dropdown="showDropdown"
        @toggle-filter-dropdown="updateDropDownValue($event)"
      />
    </title-bar>

    <div class="dashboard__main__content__home dashboard__main__content__home__agents">
      <div class="dashboard__main__content__home__right">
        <div class="transaction">
          <div class="transaction__filters">
            <search
              :search-placeholder="searchPlaceholder"
              @clear-search="clearSearch"
              @search="searchQuery($event)"
            />
          </div>
          <div class="transaction__content">
            <div
              v-if="loading"
              class="loaderContainer"
            >
              <div class="loader" />
            </div>

            <div v-else>
              <div
                v-if="noData"
                class="empty__state"
              >
                <template v-if="this.$route.query.criteria">
                  <div>No results found for that search query.</div>
                </template>
                <template v-else>
                  <div>
                    No transaction performed by agents today. See
                    <router-link
                      :to="{ name: 'fund-wallet', query: { currentPage: '1' }}"
                      class="empty__state__link"
                    >
                      all
                      fund wallet transactions
                    </router-link>
                    instead
                  </div>
                </template>
              </div>

              <div v-else>
                <div class="table table--padded">
                  <div class="table__section table__section--head">
                    <div class="table__row">
                      <div class="table__header">
                        Transaction Date
                      </div>
                      <div class="table__header">
                        Source Bank
                      </div>
                      <div class="table__header">
                        Beneficiary Agent
                      </div>
                      <div class="table__header">
                        Amount
                      </div>
                      <div class="table__header">
                        Status
                      </div>
                      <div class="table__header" />
                    </div>
                  </div>

                  <div class="table__section table__section--body">
                    <template v-for="(item, i) in fundWallet">
                      <div
                        :key="i"
                        class="table__row"
                      >
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            {{ item.transactionDate | date }}
                          </p>
                        </div>
                        <div class="table__data">
                          {{ item.sourceBank }}
                        </div>
                        <div class="table__data">
                          {{ item.merchantName }}
                        </div>
                        <div class="table__data">
                          {{ item.amount | money }}
                        </div>
                        <div class="table__data table__data--full">
                          <p class="table__data--main">
                            <span
                              v-if="item.status === 'SUCCESSFUL'"
                              class="tag tag--green"
                            >Successful</span>
                            <span
                              v-if="item.status === 'PROCESSING'"
                              class="tag tag--yellow"
                            >Processing</span>
                            <span
                              v-if="item.status === 'REVERSED'"
                              class="tag tag--gray"
                            >Reversed</span>
                            <span
                              v-if="item.status === 'FAILED'"
                              class="tag tag--red"
                            >Failed</span>
                          </p>
                        </div>

                        <div class="table__data table__data__dispense">
                          <span
                            v-if="item.status !== 'REVERSED'"
                            class="cursor-pointer"
                            title="Reverse from Agent"
                            @click="showConfirmReverseFundWallet(item)"
                          >
                            <svg
                              height="18"
                              viewBox="0 0 19 18"
                              width="18"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m.58582 5 4.41418-4.41422 1.41419 1.41422-2 2h7.58583c3.866 0 7 3.134 7 6.99998 0 3.866-3.134 7-7 7h-9.00002v-2h9.00002c2.7615 0 5-2.2386 5-5 0-2.76141-2.2385-4.99998-5-4.99998h-7.58583l2 2-1.41419 1.41421z"
                              />
                            </svg>
                          </span>
                        </div>

                        <div class="table__data">
                          <span
                            v-if="isReversible(item) && isAnyOperationPermitted(['commitStagedFundWallet'])"
                            class="cursor-pointer"
                            data-target="#confirmCommitFundWallet"
                            data-toggle="modal"
                            title="Update to Successful"
                            @click="setSelectedObject(item)"
                          >
                            <svg
                              height="20px"
                              viewBox="0 0 20 20"
                              width="20px"
                            >
                              <use xlink:href="../../assets/icons/commit.svg#commit_icon" />
                            </svg>
                          </span>
                        </div>

                        <div class="table__data">
                          <span
                            v-if="isReversible(item) && isAnyOperationPermitted(['revertStagedFundWallet'])"
                            class="cursor-pointer"
                            data-target="#confirmRevertFundWallet"
                            data-toggle="modal"
                            title="Update to Failed"
                            @click="setSelectedObject(item)"
                          >
                            <svg
                              height="10pt"
                              viewBox="0 0 329.26933 329"
                              width="10pt"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="#f44336">
                                <path
                                  d="m21.339844 329.398438c-5.460938 0-10.925782-2.089844-15.082032-6.25-8.34375-8.339844-8.34375-21.824219 0-30.164063l286.589844-286.59375c8.339844-8.339844 21.824219-8.339844 30.164063 0 8.34375 8.339844 8.34375 21.824219 0 30.164063l-286.589844 286.59375c-4.183594 4.179687-9.621094 6.25-15.082031 6.25zm0 0"
                                />
                                <path
                                  d="m307.929688 329.398438c-5.460938 0-10.921876-2.089844-15.082032-6.25l-286.589844-286.59375c-8.34375-8.339844-8.34375-21.824219 0-30.164063 8.339844-8.339844 21.820313-8.339844 30.164063 0l286.589844 286.59375c8.34375 8.339844 8.34375 21.824219 0 30.164063-4.160157 4.179687-9.621094 6.25-15.082031 6.25zm0 0"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>

                        <div class="table__data">
                          <span @click="toggleTable(item.transactionId)">
                            <svg
                              height="14"
                              viewBox="0 0 90 90"
                              width="14"
                              xmlns="http://www.w3.org/2000/svg"
                            ><path
                              d="m80 0h-70c-5.5 0-10 4.5-10 10v70c0 5.5 4.5 10 10 10h70c5.5 0 10-4.5 10-10v-70c0-5.5-4.5-10-10-10zm6 80c0 3.3-2.7 6-6 6h-70c-3.3 0-6-2.7-6-6v-70c0-3.3 2.7-6 6-6h70c3.3 0 6 2.7 6 6zm-12-62.4v.4 14c0 1.1-.9 2-2 2s-2-.9-2-2v-9.2l-14.6 14.6c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.8-.8-.8-2 0-2.8l14.6-14.6h-9.2c-1.1 0-2-.9-2-2s.9-2 2-2h14 .4c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1.1.2.1 0 0 .1 0 .1.1.2.1.4.3.6.6 0 0 .1.1.1.2s.1.1.1.2 0 .1.1.2c-.1-.1-.1-.1 0 0zm-36.6 35c.8.8.8 2 0 2.8l-14.6 14.6h9.2c1.1 0 2 .9 2 2s-.9 2-2 2h-14c-.1 0-.3 0-.4 0s-.1 0-.2-.1c-.1 0-.1 0-.2-.1-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1c-.2-.1-.4-.3-.6-.6 0 0-.1-.1-.1-.1 0-.1-.1-.1-.1-.2s0-.1-.1-.2c0-.1 0-.1-.1-.2 0-.1 0-.3 0-.4v-13.9c0-1.1.9-2 2-2s2 .9 2 2v9.2l14.6-14.6c1-.8 2.2-.8 3 0z"
                            /></svg>
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="opened.includes(item.transactionId)"
                        :key="item.transactionId"
                        class="collapsible-table"
                      >
                        <div class="card-details__content">
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for=""
                            >Tlp Ref</label>
                            <input
                              :value="item.transactionId ? item.transactionId : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for=""
                            >Source Account
                              Number</label>
                            <input
                              :value="item.sourceNuban ? item.sourceNuban : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for=""
                            >Source Account
                              Name</label>
                            <input
                              :value="item.sourceAccountName ? item.sourceAccountName : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for=""
                            >Provider</label>
                            <input
                              :value="item.providerName ? item.providerName : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for=""
                            >Provider Ref</label>
                            <input
                              :value="item.paymentRef ? item.paymentRef : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for=""
                            >Virtual NUBAN</label>
                            <input
                              :value="item.virtualNuban ? item.virtualNuban : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                          <div class="form__item">
                            <label
                              class="form__label form__label--2"
                              for=""
                            >Session ID</label>
                            <input
                              :value="item.sessionId ? item.sessionId : '--'"
                              class="form__input capitalize"
                              disabled
                              type="text"
                            >
                          </div>
                        </div>
                      </div>

                      <div
                        id="confirmReverseFundWallet"
                        :key="item.id"
                        aria-labelledby="confirmReverseFundWalletLabel"
                        class="modal fade"
                        role="dialog"
                        tabindex="-1"
                      >
                        <div
                          class="modal__dialog modal__dialog--sm"
                          role="document"
                        >
                          <div class="modal__content">
                            <div class="modal__header">
                              <div class="modal__title text-center">
                                Reverse Fund Wallet
                              </div>
                              <button
                                aria-label="Close"
                                class="close"
                                data-dismiss="modal"
                                type="button"
                              >
                                <svg
                                  height="13"
                                  viewBox="0 0 13 13"
                                  width="13"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.254 6.5l4.383-4.382A1.24 1.24 0 0 0 10.882.363L6.5 4.746 2.118.363A1.24 1.24 0 1 0 .363 2.118L4.746 6.5.363 10.882a1.24 1.24 0 0 0 1.755 1.755L6.5 8.254l4.382 4.383a1.24 1.24 0 1 0 1.755-1.755L8.254 6.5z"
                                    fill="#C0C6D8"
                                    fill-rule="nonzero"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div class="modal__body modal__body--pad">
                              <form
                                class="form"
                                @submit.prevent="reverseFundWallet"
                              >
                                <div class="form__pad">
                                  <p class="m-b-20 textCenter">
                                    Are you sure you want to reverse Fund
                                    Wallet?
                                  </p>
                                </div>

                                <div class="form__footer">
                                  <button
                                    :disabled="refundFundWalletDisabled"
                                    class="btn btn--primary btn--md btn--block"
                                    type="submit"
                                  >
                                    Yes
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                    <confirmation-modal
                      id="confirmCommitFundWallet"
                      :body="`${selectedObject.transactionId} - sure?`"
                      :on-submit="commitFundWallet"
                      button-text="Yes"
                      header="Update to Successful"
                      :disable-button="disableModalButton"
                    />
                  </div>

                  <confirmation-modal
                    id="confirmRevertFundWallet"
                    :body="`${selectedObject.transactionId} - sure?`"
                    :on-submit="revertFundWallet"
                    button-text="Yes"
                    header="Update to Failed"
                    :disable-button="disableModalButton"
                  />
                </div>

                <pagination
                  :page-number="computedPageNumbers"
                  data="fund-wallet"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import Pagination from "@/components/Pagination";
  import Search from "@/components/Search";
  import RecordsFilter from "@/components/Filter";
  import { dropdownOperations } from "@/mixins/DropdownOperations";
  import { callGetApi, callPutApi } from "@/httpClient";
  import AdminPermissionsHelper from "@/mixins/AdminPermissionsHelper";
  import ConfirmationModal from "@/components/ConfirmationModal";

  export default {
    name: "FundWallet",
    components: {
      TitleBar,
      Pagination,
      Search,
      RecordsFilter,
      ConfirmationModal
    },
    mixins: [dropdownOperations, AdminPermissionsHelper],
    data() {
      return {
        fundWallet: "",
        loading: true,
        searchPlaceholder: "Search by tlp ref, agent name, virtual nuban",
        opened: [],
        pageNumber: "",
        noData: false,
        disableModalButton: false,
        pageMetaData: "",
        selectedObject: "",
        refundFundWalletDisabled: false
      };
    },
    computed: {
      computedPageNumbers() {
        if (this.pageNumber !== "") {
          return this.pageNumber;
        } else if (window.localStorage.getItem("fundWalletPage")) {
          return JSON.parse(window.localStorage.getItem("fundWalletPage"));
        } else {
          return "";
        }
      }
    },
    watch: {
      //watch for route parameter change and execute method
      "$route": "fetchFundWallet"
    },
    created() {
      this.fetchFundWallet();
    },
    methods: {
      clearSearch() {
        this.$router.push({ name: "fund-wallet", query: { currentPage: 1 } });
      },
      isReversible(item) {
        return item.retryCount >= 1 && item.status === "PROCESSING";
      },
      setSelectedObject(fundWallet) {
        this.selectedObject = fundWallet;
      },
      fetchFundWallet() {
        const query_params = this.convertToQueryString(this.$route.query);

        callGetApi(`fund-wallet?batchSize=20&${query_params}`)
          .then(res => {
            this.noData = res.body.entity.length === 0;
            this.fundWallet = res.body.entity;
            this.pageMetaData = res.body.pageMetaData;
            this.pageNumber = Math.ceil(this.pageMetaData.totalNoOfRows / this.pageMetaData.batchSize);
            window.localStorage.setItem("fundWalletPage", Math.ceil(this.pageMetaData.totalNoOfRows / this.pageMetaData.batchSize));
            this.loading = false;
          })
          .catch(console.error);
      },

      searchQuery(criteria) {
        this.$router.push({ name: "fund-wallet", query: { criteria } });
      },
      toggleTable(id) {
        const index = this.opened.indexOf(id);
        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened.push(id);
        }
      },
      convertToQueryString(myData) {
        let url = "";
        for (const key in myData) {
          url += key + "=" + myData[key] + "&";
        }
        return url.trim("&");

      },
      showConfirmReverseFundWallet(item) {
        this.selectedObject = item;
        $("#confirmReverseFundWallet").modal();
      },
      reverseFundWallet() {
        this.refundFundWalletDisabled = true;
        callPutApi(
          `fund-wallet/reverse/${this.selectedObject.transactionId}`
        )
          .then(res => {
            this.refundFundWalletDisabled = false;
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Reverse Fund Wallet",
                text: "Fund Wallet reversed successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Reverse Fund Wallet",
                type: "warn",
                text: res.body.status.description
              });
            }
            $("#confirmReverseFundWallet").modal("hide");
            this.fetchFundWallet();
          })
          .catch(err => {
            this.refundFundWalletDisabled = false;
            console.error(err);
          });
      },
      commitFundWallet() {
        this.disableModalButton = true;
        const queryString = this.convertToQueryString({ tellerpointRef: this.selectedObject.transactionId });
        callPutApi(`merchant/commit-staged-fund-wallet?${queryString}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Commit Fund Wallet",
                text: "Fund Wallet Committed successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Commit Fund Wallet",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error)
          .finally(() => {
            this.disableModalButton = false;
            $("#confirmCommitFundWallet").modal("hide");
            this.fetchFundWallet();
          });
      },
      revertFundWallet() {
        this.disableModalButton = true;
        const queryString = this.convertToQueryString({ tellerpointRef: this.selectedObject.transactionId });
        callPutApi(`merchant/revert-staged-fund-wallet?${queryString}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Revert Fund Wallet",
                text: "Fund Wallet Reverted successfully"
              });
            } else {
              this.$notify({
                group: "general",
                title: "Revert Fund Wallet",
                type: "warn",
                text: res.body.status.description
              });
            }
          })
          .catch(console.error)
          .finally(() => {
            this.disableModalButton = false;
            $("#confirmRevertFundWallet").modal("hide");
            this.fetchFundWallet();
          });
      }
    }
  };
</script>
