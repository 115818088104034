<template>
  <div class="dashboard__main__content__container">
    <title-bar :title="pageTitle">
      <div class="dashboard__main__content__row__item">
        <router-link
          :to="{ name: 'admins', query: { currentPage: 1 } }"
          class="dashboard__main__content__back"
        >
          <back-icon />
          <span>Back to Admins</span>
        </router-link>
      </div>
    </title-bar>

    <div class="">
      <search
        :search-placeholder="searchPlaceholder"
        @clear-search="clearSearch"
        @search="searchQuery($event)"
      />
      <div
        v-if="loading"
        class="loaderContainer"
      >
        <div class="loader" />
      </div>
    </div>

    <div class="card-grid">
      <div
        v-for="(permission, position) in filteredAndSortedAdminPermissions"
        :key="position"
        class="card-column"
      >
        <div class="card-header">
          <p class="card-title">
            {{ permission.title ? permission.title : (permission.operation | splitByCamelCase) }}
          </p>
          <div class="switch">
            <input
              :id="position"
              v-model="permission.status"
              class="switch__input"
              type="checkbox"
              @change="togglePermission($event, permission)"
            >
            <label
              :for="position"
              class="switch__label"
            />
          </div>
        </div>
        <p class="card-footer gap-s">
          {{ permission.displayName ? permission.displayName : (permission.operation | splitByCamelCase) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleBar from "@/components/TitleBar";
  import "vue-select/dist/vue-select.css";
  import "vue2-datepicker/index.css";
  import BackIcon from "@/components/icons/BackIcon";
  import { mapGetters } from "vuex";
  import { callGetApi, callPutApi } from "@/httpClient";
  import Search from "@/components/Search.vue";

  export default {
    components: {
      TitleBar,
      BackIcon,
      Search,
    },
    data() {
      return {
        searchCriteria: "",
        adminPermissions: [],
        loading: true,
        adminData: [],
        pageTitle: "",
        searchPlaceholder: "Search by permission name",
      };
    },
    computed: {
      ...mapGetters(["adminAccountInfo"]),
      filteredAndSortedAdminPermissions() {
        function compare(a, b) {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        }

        return this.adminPermissions.filter(permission => {
          return permission.title.toLowerCase().includes(this.searchCriteria.toLowerCase());
        }).sort(compare);
      }
    },
    created() {
      this.fetchAdmin();
      this.fetchAdminPermissions();
    },
    beforeRouteEnter(from, to, next) {
      callGetApi(`admin/${from.params.id}`)
        .then(res => {
          if (res.body.status.code !== 0) {
            next(false);
          } else {
            next(vm => {
              vm.adminData = res.body.entity;
            });
          }
        })
        .catch(err => {
          console.error(err);
          next(false);
        });
    },
    methods: {
      fetchAdmin() {
        callGetApi(`admin/${this.$route.params.id}`)
          .then(res => {
            this.loading = false;
            this.adminData = res.body.entity;
            this.pageTitle = res.body.entity.firstName + " " + res.body.entity.surname+"'s Permissions";
          })
          .catch(console.error);
      },
      fetchAdminPermissions() {
        callGetApi(`${this.$route.params.id}/operations`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.noData = res.body.entity.length === 0;
              this.adminPermissions = res.body.entity;
              this.loading = false;
            }
          })
          .catch(console.error);
      },
      togglePermission(event, permission) {
        const action = permission.status ? "enabled" : "disabled";
        const id = this.adminData.id;
        callPutApi(`${id}/operations/${permission.operation}/${permission.status}`)
          .then(res => {
            if (res.body.status.code === 0) {
              this.$notify({
                group: "general",
                title: "Admin Permission Update",
                text: `Successfully ${action} "${this.$options.filters.splitByCamelCase(permission.operation)}" permission`
              });
            } else {
              this.$notify({
                group: "general",
                title: "Admin Permission Update",
                type: "warn",
                text: res.body.status.description
              });
              event.target.checked = false;
            }
          })
          .catch(console.error);
      },
      clearSearch() {
        this.searchCriteria = "";
      },
      searchQuery(criteria) {
        this.searchCriteria = criteria;
      }
    }
  };
</script>

<style scoped>

  .card-grid {
    display: grid;
    column-gap: 20px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    width: 100%;
    grid-auto-rows: 1fr;
  }

  @media only screen and (max-width: 600px) {
    .card-grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (min-width: 768px) {
    .card-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .card-column {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgb(112 121 139 / 18%);
    background-color: #ffffff;
    padding: 15px;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .card-title {
    font-size: 16px;
    font-weight: 500;
  }

  .card-footer {
    color: rgba(84, 98, 120, 0.4);
    font-size: 13px;
  }
</style>
